app.directive('linkTarget', function() {
    return {
        restrict: 'A',
        link: function(scope, elem, attrs) {
            attrs.$observe('href', function() {
                var a = elem[0];
                if (location.host.indexOf(a.hostname) !== 0)
                    a.target = '_blank';
                if (a.href.toLowerCase().endsWith('.pdf'))
                    a.target = '_blank';
            });
        }
    }
});
 