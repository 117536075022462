app.controller('LanguageSwitcher', ['$scope', '$rootScope', 'queryStringService', 'helperService', function ($scope, $rootScope, queryStringService, helperService) {
    var History = window.history;
    $scope.changeLanguage = function(currentLanguage) {
        var language = {
            currentLanguage: currentLanguage
        };
        $rootScope.$emit('languageChange', language);
        $scope.currentLanguage = currentLanguage;
        var queryString = queryStringService.updateQueryString('language', currentLanguage);
        //History.pushState(null, document.title, queryString);
        History.replaceState(null, document.title, queryString);
    };
    $scope.isCurrent = function(language) {
        return $scope.currentLanguage == language || (helperService.isNullOrEmpty($scope.currentLanguage) && language == 'en');
    };


    var activeLanguage = queryStringService.getParameterByName('language');
    if(activeLanguage != ""){
        $scope.changeLanguage(activeLanguage, false);
    }
}]);