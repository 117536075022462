app.directive('truncate', ['$window', function($window) {
    return {
        restrict: 'A',
        link: function(scope, elem, attrs) {
            truncate = function(ele, height) {
                $(ele).css({ "max-height": height }).addClass("block-with-text");
                // $(ele).dotdotdot({
                //     ellipsis : '... ', /*	The text to add as ellipsis. */
                //     wrap : 'word', /*	How to cut off the text/html: 'word'/'letter'/'children' */
                //     fallbackToLetter : true, /*	Wrap-option fallback to 'letter' for long words */
                //     after : null, /*	jQuery-selector for the element to keep and put after the ellipsis. */
                //     watch : false, /*	Whether to update the ellipsis: true/'window' */
                //     height : height, /*	Optionally set a max-height, can be a number or function. If null, the height will be measured. */
                //     tolerance : 0, /*	Deviation for the height-option. */
                //     callback : function(isTruncated, orgContent) { 
                //     }, /*	Callback function that is fired after the ellipsis is added, receives two parameters: isTruncated(boolean), orgContent(string). */
                //     lastCharacter : {
                //         remove : [ ' ', ',', ';', '.', '!', '?' ], /*	Remove these characters from the end of the truncated text. */
                //         noEllipsis : [] /*	Don't add an ellipsis if this array contains the last character of the truncated text. */ 
                //     }
                // });
            }
            destroy = function(ele) {
                $(ele).trigger('destroy');
            }
            originalContent = function(ele) {
                return $(ele).trigger('originalContent');
            }
            var height = "200px";
            if (attrs.truncate) {
                //height = parseInt(attrs.truncate, 10);
                height = attrs.truncate;
            }
            truncate(elem[0], height);
        }
    }
}]);