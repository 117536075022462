app.controller('SiteSearch', ['$scope', '$http', '$element', '$window', 'queryStringService', 'helperService', function ($scope, $http, $element, $window, queryStringService, helperService) {
    // data-attrs
    $scope.pageSize = 10;
    $scope.noResults = 'No Items Found';

    $scope.pageNum = 1;
    $scope.showTerms = true;
    $scope.showViewMore = false;
    $scope.showClearAll = false;
    $scope.hasResults = false;
    $scope.searchComplete = false;
    $scope.isLoading = false;
    $scope.lastSearchTerm = '';

    $scope.filterByOptions = [];
    $scope.sortByOptions = [];
    $scope.terms = [];
    $scope.sortByVal = ''
    $scope.query = '';

    $scope.results = [];
    $scope.page = 1;

    var customSearchControl;
    var History = window.history;

    $scope.init = function () {

        if ($element.attr('data-page-size')) {
            $scope.pageSize = $element.attr('data-page-size');
        }
        if ($element.attr('data-no-results')) {
            $scope.noResults = $element.attr('data-no-results');
        }

        // $scope.filterByOptions.push(createSelectOptionObject('', ''));
        $scope.filterByOptions.push(createSelectOptionObject('Professionals', 'professional'));
        $scope.filterByOptions.push(createSelectOptionObject('Services / Industries', 'service'));
        $scope.filterByOptions.push(createSelectOptionObject('Vedder Thinking', 'vedder-thinking'));
        $scope.filterByOptions.push(createSelectOptionObject('About Us', 'about-us'));
        $scope.filterByOptions.push(createSelectOptionObject('Diversity', 'diversity'));
        $scope.filterByOptions.push(createSelectOptionObject('Careers', 'career'));

        $scope.sortByOptions.push(createSelectOptionObject('Relevance', ''));
        $scope.sortByOptions.push(createSelectOptionObject('Date', 'date'));

        if (window.location.search) {
            clearSearch(true);

            $scope.query = queryStringService.getParameterByName('q');
            if (!helperService.isNullOrEmpty($scope.query)) addSearchTerm($scope.query);
            $scope.filterByVal = queryStringService.getParameterByName('f');
            $scope.sortByVal = queryStringService.getParameterByName('s');
            $scope.query = '';
            doSearch();
        }

     $(window).scroll(function () {
            if ($scope.showViewMore && $(window).scrollTop() + $(window).height() > $(document).height() - 100) {
                $scope.addPage();
            }
        });

    };

    function createSelectOptionObject(text, value) {
        return { text: text, value: value };
    }

    $scope.clearQuery = function () {
        $scope.query = '';
    };

    $scope.performSearch = function () {
        clearSearch(false);

        //add keyword to term list
        addSearchTerm($scope.query);

        //call doSearch
        doSearch();
    };

    //logic to clear all filters and add fitler
    $scope.clearAndAddFilter = function () {
        clearSearch(false);

        doSearch();
    };

    $scope.clearAndSetSort = function () {
        clearSearch(false);

        doSearch();
    }

    $scope.addPage = function () {
        if (!$scope.isLoading && $scope.showViewMore) {
            $scope.pageNum++;
            doSearch();
        }
    };

    function addSearchTerm(query) {
        if (query && query !== '') {
            var array = query.split('|');
            for (var i = 0; i < array.length; i++) {
                if (!helperService.isNullOrEmpty(array[i])) {
                    $scope.terms.push({ text: array[i], value: array[i] });
                    $scope.lastSearchTerm = array[i];
                    $scope.showClearAll = true;
                }
            }
            if (array.length > 1) $scope.lastSearchTerm = '';
        }
        $scope.query = '';
    };

    $scope.removeSearchTermAndSearch = function (index) {
        if (index <= $scope.terms.length) $scope.terms.splice(index, 1);
        if ($scope.terms.length == 0) $scope.showClearAll = false;
        $scope.lastSearchTerm = '';
        clearSearch(false);
        doSearch();
    };

    $scope.clearSearch = function () {
        clearSearch(true);
        History.pushState(null, document.title, '?')
    };

    function clearSearch(clearTerms) {
        if (clearTerms) $scope.terms = [];

        $scope.pageNum = 1;
        $scope.showViewMore = false;
        $scope.searchComplete = false;
        $scope.showClearAll = false;
        $scope.results = [];
    };

    function getQuery(delimiter) {
        var flatTerms = '';
        if (!delimiter) delimiter = ' ';
        for (var i = 0; i < $scope.terms.length; i++) {
            flatTerms += (i > 0 ? delimiter : '') + $scope.terms[i].value;
        }
        return flatTerms;
    };

    function getSearchResults() {
        var filter = ($scope.filterByVal) ? $scope.filterByVal : "";
        var apiPs = {
            q: getQuery(),
            category: filter,
            page: $scope.pageNum,
            pageSize: $scope.pageSize,
            sort: $scope.sortByVal
        };

        var apiQS = $.param(apiPs);

        $scope.isLoading = true;
        $scope.searchComplete = false;

        $http.get('/sitecore/api/ssc/webapi/sitesearch/1/search/?' + apiQS)
            .then(function (response) {

                if (response.data.data.list && response.data.data.list.length > 0) {

                    var data = response.data.data.list.map(function (item) {
                        return {
                            //value.pagemap.cse_thumbnail[0].src
                            title: item.htmlTitle,
                            link: item.link,
                            snippet: item.htmlSnippet,
                            formattedlink: item.htmlFormattedUrl,
                            thumbnail: item.thumbnail
                        }
                    })

                    $scope.results = $scope.results.concat(data);
                    $scope.hasResults = true;

                }
                else {
                    $scope.results = [];
                    $scope.hasResults = false;
                    $scope.showViewMore = false;
                }

                if (response.data.hasMore) {
                    $scope.showViewMore = true;
                }
                else {
                    $scope.showViewMore = false;
                }

                $scope.isLoading = false;
                $scope.searchComplete = true;
            });
    }

    function doSearch() {
        if (getQuery() == "") {
            $scope.hasResults = false;
            return;
        }

        getSearchResults();

        var qs = '?';
        var q = getQuery('|');
        if (!helperService.isNullOrEmpty(q)) qs = queryStringService.addQueryString('q', getQuery('|'), qs);
        if (!helperService.isNullOrEmpty($scope.filterByVal)) qs = queryStringService.addQueryString('f', $scope.filterByVal, qs);
        if (!helperService.isNullOrEmpty($scope.sortByVal)) qs = queryStringService.addQueryString('s', $scope.sortByVal, qs);
        History.pushState(null, document.title, qs.replace('?&', '?'));
    };



    // init
    $scope.init();
}])