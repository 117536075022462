app.directive('ngClickOutClose', ['$document', function ($document) {
    //click-any-where-but-here
    return {
        restrict: 'A',
        link: function (scope, elem, attr, ctrl) {
            var elemClickHandler = function (e) {
                e.stopPropagation();
            };

            var docClickHandler = function () {
                scope.$apply(attr.ngClickOutClose);
            };

            elem.on('click', elemClickHandler);
            $document.on('click', docClickHandler);

            // teardown the event handlers when the scope is destroyed.
            scope.$on('$destroy', function () {
                elem.off('click', elemClickHandler);
                $document.off('click', docClickHandler);
            });
        }
    };
}]);