angular.module('vedderFilters', []).
    filter('paginationRange', function () {
        return function (list, index) {
            if (list === undefined || index === undefined)
                return;
            var maxShow = 7;
            if (index < 4) {
                return createRange(1, getMin(list.length, maxShow));
            } else {
                return createRange(index - 2, getMin(list.length, index + 4));
            }

            function createRange(start, stop) {
                var out = [];
                for (var i = start; i <= stop; i++) {
                    out.push(i);
                }
                return out;
            }
            function getMin(listMax, maxNumber) {
                return listMax > maxNumber ? maxNumber : listMax;
            }
        };
    });

//angular.module('vedderFilters', []).
//    filter('unsafe', function ($sce) {
//        return $sce.trustAsHtml;
//    });
