app.controller('ServicesLanding', ['$scope', '$http', '$element', '$sce', 'queryStringService', 'helperService', function($scope, $http, $element, $sce, queryStringService, helperService) {
    $scope.activeTab = 'services';
    $scope.columnCount = 3;
    $scope.sanitizeFields = [];
    $scope.isLoading = false;
    $scope.count = 0;
    $scope.keyword = '';

    $scope.columns = [];

    $scope.init = function() {
        if ($element.attr('data-column-count'))
            $scope.columnCount = parseInt($element.attr('data-column-count'));
        if ($element.attr('data-sanitize-field'))
            $scope.sanitizeFields = $element.attr('data-sanitize-field').split(',');

        if (!helperService.isNullOrEmpty(queryStringService.getParameterByName('tab')))
            $scope.tab = queryStringService.getParameterByName('tab');
        if (!helperService.isNullOrEmpty(queryStringService.getParameterByName('keyword'))) {
            $scope.keyword = queryStringService.getParameterByName('keyword');
            $scope.keywordval = $scope.keyword;
            $scope.activeTab = 'search';
        }
        if (!helperService.isNullOrEmpty(queryStringService.getParameterByName('tab'))) {
            SetActiveTab(queryStringService.getParameterByName('tab'));
        }

        if ($scope.activeTab === 'search')
            $scope.search();
    };

    $scope.toggle = function() {
        if ($scope.activeTab === 'services') {
            SetActiveTab('industries');
        } else {
            SetActiveTab('services');
        }
        $scope.keywordval = '';
    };

    $scope.toggleServices = function() {
        SetActiveTab('services');
    }

    $scope.toggleIndustries = function() {
        SetActiveTab('industries');
    }

    $scope.clearSearch = function() {
        $scope.keywordval = ''
        $scope.keyword = ''
        SetActiveTab('services')
    }

    $scope.search = function() {
        if ($scope.keywordval.length < 3 ) {
            return;
        }

        $scope.isLoading = true;
        $scope.keyword = $scope.keywordval;
        //ClearSearchForm();
        SetActiveTab('search');
        BuildQueryString();
        $http.get('/sitecore/api/ssc/webapi/search/1/services' + $scope.queryString)
            .success(function (response) {
                angular.forEach(response.data.list, function(item) {
                    angular.forEach($scope.sanitizeFields, function(fieldName) {
                        var sanitized = $sce.trustAsHtml(item[fieldName]);
                        item[fieldName] = sanitized;
                    });
                });
                
                $scope.columns = [];
                $scope.count = response.data.count;
                if ($scope.count > 0) {
                    var remainder = $scope.count % $scope.columnCount;
                    var columnSize = parseInt($scope.count / $scope.columnCount);

                    var runningTake = 0;
                    for (var i = 0; i < $scope.columnCount; i++) {
                        var column = [];
                        var skip = runningTake;
                        var take = columnSize + (remainder-- > 0 ? 1 : 0);
                        runningTake += take;
                        for (var j = 0; j < take; j++) {
                            column.push(response.data.list[skip + j]);
                        }
                        $scope.columns.push(column);
                    }
                }

                $scope.isLoading = false;
            })
            .error(function (data, status, headers, config) {
            });
    };

    function ClearSearchForm() {
        $scope.keywordval = '';
    };

    function SetActiveTab(val) {
        $scope.activeTab = val;
        BuildQueryString();
    };

    function BuildQueryString() {
        $scope.queryString = '';
        $scope.queryString = queryStringService.addQueryString('tab', $scope.activeTab, $scope.queryString);
        $scope.queryString = queryStringService.addQueryString('keyword', $scope.keyword, $scope.queryString);

        //history query string
        var updatedQueryString = queryStringService.addQueryString('keyword', $scope.keyword, $scope.queryString);

        if ($scope.activeTab === 'services') {
            updatedQueryString = queryStringService.removeQueryString('tab', $scope.SetActiveTab, updatedQueryString);
        }

        if (updatedQueryString !== '?') {
            history.replaceState(null, document.title, updatedQueryString.replace('?&', '?'));
        }
    };

    $scope.init();
}]);
