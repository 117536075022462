app.directive('placeholder', ['$timeout', '$log', function($timeout, $log) {
	if (!/MSIE [89]/.test(navigator.userAgent)) {
		return {};
	}
	return {
		link: function(scope, elm, attrs) {
			$log.debug('applying `placeholder` attribute API filler');

			if (attrs.type === 'password') {
				return;
			}
			$timeout(function() {
				elm.val(attrs.placeholder).focus(function() {
					var node = $(this);

					if (node.val() === node.attr('placeholder')) {
						node.val('');
					}
				}).blur(function() {
					var node = $(this);
					if (node.val() === '') {
						node.val(node.attr('placeholder'));
					}
				});
			});
		}
	};
}]);
