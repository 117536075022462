app.controller('EmailDisclaimer', ['$scope', '$window', 'queryStringService', function ($scope, $window, queryStringService) {
    queryString = $window.location.search;

    $scope.init = function () {};

    $scope.SendEmail = function() {
        var emailprefix = queryStringService.getParameterByName("emailprefix");
        var emailDomain = queryStringService.getParameterByName("emaildomain");
        $scope.goto("mailto:" + emailprefix + "@" + emailDomain);
        setTimeout(function () { window.close(); }, 200);
    };

    $scope.goto = function (path) {
        location.assign(path);
    };

    // init
    $scope.init();
}]);