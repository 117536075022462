// var debounce = require('lodash.debounce');
app.directive('scrollDirective', ['$document', function ($document) {
  return {
    restrict: 'A',
    link: function (scope, element, attrs) {

      // Returns a function, that, as long as it continues to be invoked, will not
      // be triggered. The function will be called after it stops being called for
      // N milliseconds. If `immediate` is passed, trigger the function on the
      // leading edge, instead of the trailing.
      function debounce(func, wait, immediate) {
        var timeout;
        return function() {
          var context = this, args = arguments;
          var later = function() {
            timeout = null;
            if (!immediate) func.apply(context, args);
          };
          var callNow = immediate && !timeout;
          clearTimeout(timeout);
          timeout = setTimeout(later, wait);
          if (callNow) func.apply(context, args);
        };
      };

      $document.bind("scroll", debounce(function () {
        if ((window.innerHeight + window.pageYOffset) >= (document.documentElement.scrollHeight - 1)) {
          scope.$apply(attrs.scrollDirective)
        }
      }, 50))
    }
  }
}])