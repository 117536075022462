var app = angular.module('vedder', ['ngSanitize','vedderFilters']);

app.config(['$provide', function ($provide) {
    $provide.decorator('$browser', ['$delegate', function ($delegate) {
        $delegate.onUrlChange = function () { };
        $delegate.url = function () { return "" };
        return $delegate;
    }]);
}]);

app.run(['$rootScope', function($rootScope){
  $rootScope.goToUrl = function(url){
      window.location = url;
  }
}]);