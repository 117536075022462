app.directive('a', function() {
    return {
        restrict: 'E',
        link: function(scope, elem, attrs) {
            attrs.$observe('href', function (hrefVal) {
                var a = elem[0];
                if (attrs['nodisclaimer'] == undefined && hrefVal != undefined && hrefVal != null && hrefVal != "" && hrefVal.toLowerCase().indexOf('mailto:') > -1 && hrefVal.toLowerCase().indexOf('@') > -1) {
                    var name = hrefVal.substring(hrefVal.toLowerCase().indexOf('mailto:')+7, hrefVal.toLowerCase().indexOf('@'));
					var domain = hrefVal.substring(hrefVal.toLowerCase().indexOf('@')+1);
					var domainsmall = domain;
					if(hrefVal.toLowerCase().indexOf('?') > 0 )
                    	domainsmall = hrefVal.substring(hrefVal.toLowerCase().indexOf('@')+1,hrefVal.toLowerCase().indexOf('?'));
                    if(name != undefined && name != null && name != "") {
                        a.href = "javascript:window.open('/EmailDisclaimer?emailprefix=" + name + "&emaildomain=" + domain +
                            "','disclaimer','height=540,width=640,location=no,menubar=no,resizable=no,scrollbars=no,toolbar=no,status=no,left=100,top=100');void(0);";
                        if (a.innerHTML != undefined && a.innerHTML != null && a.innerHTML != "" && a.innerHTML.toLowerCase().indexOf('@') > -1) {
                            a.innerHTML = name + "<span>@</span>" + domainsmall;
                        }
                    }
                }
				//else if(hrefVal != undefined && hrefVal != null && hrefVal != "" && hrefVal.toLowerCase().indexOf('javascript') <= -1) {
				//	if (location.host.indexOf(a.hostname) !== 0)
				//		a.target = '_blank';
				//}
            });
        }
    }
});
 
