app.factory("filterService", function () {
    //This is a simple service to share filters across controllers
    var filters = [];
    return {
        /**
			* @Retrieves the filters
			* @returns list of filters
			*/
        get: function () {
            return filters;
        },

        /**
			* @Add a filter - will not allow duplicate filters
			* @returns list of filters
			*/
        add: function (filter) {
            var sectionExist = false;
            var valueExist = false;
            for (var i = 0; i < filters.length; i++) {
                if (filters[i].sectionName == filter.sectionName) {
                    sectionExist = true;
                    for (var j = 0; j < filters[i].values.length; j++) {
                        if (filters[i].values[j].filterValue == filter.value)
                            valueExist = true;
                    }
                    if(!valueExist) {
                        var value = {filterValue: filter.value, filterName: filter.name}
                        filters[i].values.push(value)
                    }
                }
            }
            if(sectionExist == false){
                var value = {filterValue: filter.value, filterName: filter.name}
                var values = [];
                values.push(value);
                var filter = {sectionName: filter.sectionName, values: values};
                filters.push(filter);
            }
        },
        remove: function (filter) {
            var ndx = -1;
            for (var i = 0; i < filters.length; i++) {
                if (filters[i].sectionName == filter.sectionName){
                    for(var j=0; j <filters[i].values.length; j++){
                        if(filters[i].values[j].filterValue == filter.value) {
                            ndx=j;
                            break;
                        }
                    }
                    if(ndx > -1) {
                        filters[i].values.splice(ndx, 1);
                    }
                }
            }
            this.clearEmptySections();
        },
        getQueryStringBySection: function(sectionName,deliminator){
            for (var i = 0; i < filters.length; i++) {
                if (filters[i].sectionName == sectionName)
                {
                  if(filters[i].values.length > 0) {
                      var filterString = "";
                      for(var j=0; j <filters[i].values.length; j++){
                          filterString = filterString + filters[i].values[j].filterValue+deliminator;
                      }
                      if(filterString.charAt( filterString.length-1 ) == deliminator) {
                          filterString = filterString.slice(0, -1)
                      }
                      return filterString;
                  }
                  return "";
                }
            }
        },
        removeBySection: function (sectionName) {
            var ndx = -1;
            for (var i = 0; i < filters.length; i++) {
                if (filters[i].sectionName == sectionName){
                    ndx=i;
                    break;
                }
            }
            if(ndx > -1) {
                filters.splice(ndx, 1);
            }
        },
        removeByValue: function (filterValue) {
            var ndx = -1;
            for (var i = 0; i < filters.length; i++) {
                for (var j = 0; j < filters[i].values.length; j++) {
                    if (filters[i].values[j].filterValue == filterValue) {
                        ndx = j;
                        break;
                    }
                }
                if (ndx > -1) {
                    filters[i].values.splice(ndx, 1);
                }
            }
            this.clearEmptySections();
        },
        clearEmptySections: function(){
            var removalIndexes = []
            for (var i = 0; i < filters.length; i++) {
                if(filters[i].values.length <= 0){
                   removalIndexes.push(i);
                }
            }
            removalIndexes.sort(function(a,b){ return b - a; });
            for (var i = removalIndexes.length -1; i >= 0; i--)
                filters.splice(removalIndexes[i],1);
        },
        filterExists: function (filter) {
            for (var i = 0; i < filters.length; i++) {
                if (filters[i].sectionName == filter.sectionName){
                    for(var j=0; j <filters[i].values.length; j++){
                        if(filters[i].values[j].filterValue == filter.value) {
                            return true
                        }
                    }
                }
            }
            return false;
        },
        filterByValueExists: function (filterValue) {
            for (var i = 0; i < filters.length; i++) {
                for (var j = 0; j < filters[i].values.length; j++) {
                    if (filters[i].values[j].filterValue == filterValue) {
                        return true;
                    }
                }
            }
            return false;
        },
        anyFilterExists: function(){
            for (var i = 0; i < filters.length; i++) {
                if(filters[i].values.length > 0){
                    return true;
                }
            }
            return false;
        },
        clear: function () {
            filters = [];
        }
    };
});
