app.controller('ContactUsForm', ['$scope', '$http', '$element', '$sce', 'queryStringService', function ($scope, $http, $element, $sce, queryStringService) {
    $scope.success = false;
    $scope.submitted = false;
    $scope.recaptchaSiteKey = '';

    $scope.errorMessage = '';
    
    $scope.firstName = '';
    $scope.lastName = '';
    $scope.title = '';
    $scope.company = '';
    $scope.email = '';
    $scope.subject = '';
    $scope.comments = '';
    
    $scope.validationErrors = [];

    $scope.init = function() {
        if ($element.attr('data-sitekey')) {
            $scope.recaptchaSiteKey = $element.attr('data-sitekey');
        }
    };

    $scope.submit = function () {
        BuildQueryString().then(function (query) {
            $http.post('/sitecore/api/ssc/webapi/forms/1/ContactUs' + query).success(function (response) {
                $scope.success = response.data.success;
                $scope.errorMessage = $sce.trustAsHtml(response.data.message);
                $scope.submitted = true;
                if ($scope.success) {
                    $scope.firstName = '';
                    $scope.firstName = '';
                    $scope.lastName = '';
                    $scope.title = '';
                    $scope.company = '';
                    $scope.email = '';
                    $scope.subject = '';
                    $scope.comments = '';
                }
            }).error(function (data, status, headers, config) {
                $scope.errorMessage = $sce.trustAsHtml('<p>unexpected error!</p>');
            });
            return false;
        })
    };

    function BuildQueryString() {
        $scope.queryString = '';
        var fieldNames = ['firstName', 'lastName', 'title', 'company', 'email', 'subject', 'comments'];
        for (var index in fieldNames) {
            var fieldName = fieldNames[index];
            if (!IsNullOrEmpty($scope[fieldName])) {
                $scope.queryString = queryStringService.addQueryString(fieldName, encodeURIComponent($scope[fieldName]), $scope.queryString);
            }
        }
        return validateRecaptcha().then(function(response) {
            $scope.queryString = queryStringService.addQueryString('recaptchaResponse', response, $scope.queryString);
            return $scope.queryString;
        });
    };

    function validateRecaptcha() {
        return new Promise(function (res, rej) {
            grecaptcha.ready(function () {
                grecaptcha.execute($scope.recaptchaSiteKey, { action: 'submit' })
                    .then(function (response) {
                        return res(response);
                    });
            })
        })
    }

    function IsNullOrEmpty(value) {
        return (value == undefined || value == null || value == "");
    };

    $scope.init();
}]);