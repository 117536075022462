app.controller("ProfessionalSearch", ['$scope', '$http', '$element', '$window', '$sce', '$timeout', 'filterService', 'queryStringService', 'helperService', function ($scope, $http, $element, $window, $sce, $timeout, filterService, queryStringService, helperService) {
    $scope.letters = { A: false, B: false, C: false, D: false, E: false, F: false, G: false, H: false, I: false, J: false, K: false, L: false, M: false, N: false, O: false, P: false, Q: false, R: false, S: false, T: false, U: false, V: false, W: false, X: false, Y: false, Z: false };
    $scope.searchType = "Professionals";
    $scope.pageSize = 10;
    $scope.count = 0;
    $scope.pageNum = 0;
    $scope.list = [];
    $scope.searchComplete = false;
    $scope.isLoading = false;
    $scope.sortOrder = 0;
    $scope.sortBy = 0;
    $scope.isInitialSearch = true;
    $scope.administrativeTeamLabel =  "Administrative Team";
    var History = window.history;

    //All Search Parameters
    $scope.lastNameLetter = "";
    $scope.name = "";
    $scope.administrativeTeam = false;
    //Section Names
    $scope.lastNameLetterSection = "Letter";
    $scope.nameSection = "Name";
    $scope.industriesSection = "Industry";
    $scope.servicesSection = "Service Area";
    $scope.levelsSection = "Title";
    $scope.locationsSection = "Location";
    $scope.schoolsSection = "School";
    $scope.administrativeTeamSection = "Administrative Team";
    $scope.loadAll = false;

    $scope.nameTypeAheadList = [];

    var firstSearch = true;
    var scrollSearch = false;
    var administrativeTeamBubble = angular.element('.standard-search-form__administrative-team')

    $scope.hasFiltersApplied = function() {
        return $scope.lastNameLetter != '' ||
            $scope.name != '' ||
            $scope.filters.length > 0
    };

    $scope.scrollDisabled = function() {
        return ((($scope.pageNum+1) * $scope.pageSize >= $scope.count) || $scope.loadAll);
    };

    $scope.init = function() {
        getAvailableLetters();
        $scope.pageSize = parseInt($element.attr('data-page-size'));
        $scope.sanitizeFields = [];
        if ($element.attr('data-sanitize-field'))
            $scope.sanitizeFields = $element.attr('data-sanitize-field').split(',');
        if ($element.attr('data-sort-by'))
            $scope.sortBy = $element.attr('data-sort-by');
        if ($element.attr('data-sort-order'))
            $scope.sortOrder = $element.attr('data-sort-order');
        if ($element.attr('data-administrative-label'))
            $scope.administrativeTeamLabel = $element.attr('data-administrative-label');

        $scope.filters = filterService.get();

        //Filters From Query String
        if (!helperService.isNullOrEmpty(queryStringService.getParameterByName('administrativeTeam'))) {
            $scope.administrativeTeam = helperService.stringToBoolean(queryStringService.getParameterByName('administrativeTeam')) ;
        }

        checkForFilters('lastNameLetter');
        checkForFilters('name');
        checkForFilters('industries');
        checkForFilters('services');
        checkForFilters('levels');
        checkForFilters('locations');
        checkForFilters('schools');
        checkForFilters('administrativeTeam');

        if (!helperService.isNullOrEmpty(queryStringService.getParameterByName('pageNum'))) {
            $scope.pageNum = parseInt(queryStringService.getParameterByName('pageNum')) ;
        }
        if (!helperService.isNullOrEmpty(queryStringService.getParameterByName('sortBy'))) {
            $scope.sortBy = parseInt(queryStringService.getParameterByName('sortBy')) ;
        }
        if (!helperService.isNullOrEmpty(queryStringService.getParameterByName('sortOrder'))) {
            $scope.sortOrder = parseInt(queryStringService.getParameterByName('sortOrder'));
        }
        if (!helperService.isNullOrEmpty(queryStringService.getParameterByName('loadAll'))) {
            $scope.loadAll = !!queryStringService.getParameterByName('loadAll');
        }

        var scrollPosition = 0;
        if (!helperService.isNullOrEmpty(queryStringService.getParameterByName('scroll'))) {
            scrollPosition = parseInt(queryStringService.getParameterByName('scroll'));
        }

        $('html, body').keydown(function (e) {
            if (e.which === 13) {
                scrollToResults();
            }
        });

        //angular.element($window).on("click", function(event) {
        //    if (angular.element("#nametypeahead").has(event.srcElement || event.target).length === 0) {
        //        $scope.nameTypeAheadList = [];
        //        $scope.$apply();
        //    }
        //});

        $scope.Search(scrollPosition);
        scrollSearch = true;
    };

    $scope.searchLetterClicked = function(filterValue, filterText, filterSectionName) {
        $scope.addFilter(filterValue, filterText, filterSectionName, false);
    };

    $scope.addSearchTerms = function() {
        $scope.loadAll = false;
        if(!helperService.isNullOrEmpty($scope.name)){
            $scope.addFilter($scope.name,$scope.name,$scope.nameSection,true);
        }
        if($scope.administrativeTeam){
            $scope.addFilter($scope.administrativeTeam,$scope.administrativeTeamLabel,$scope.administrativeTeamSection,true);
        }
        $scope.Search(-1);
    };

    $scope.nameTypeAhead = function() {
        if ($scope.name.length > 2) {
            $http.get('/sitecore/api/ssc/webapi/typeahead/1/professionals?q=' + encodeURIComponent($scope.name)).success(function (response) {
                try {
                    $scope.nameTypeAheadList = response.data.list;
                }
                catch (ex) { }
            }).error(function (data, status, headers, config) {

            });
        } else {
            $scope.nameTypeAheadList = [];
        }
    };

    $scope.Search = function (jumpTo) {
        $scope.isLoading = true;
        $scope.nameTypeAheadList = [];

        BuildQueryString();
        $http.get("/sitecore/api/ssc/webapi/search/1/professionals" + $scope.queryString).success(function (response) {
            angular.forEach(response.data.list, function (item) {
                angular.forEach($scope.sanitizeFields, function (fieldName) {
                    item[fieldName] = $sce.trustAsHtml(item[fieldName]);
                });
            });

            if ($scope.pageNum > 0)
                $scope.list = $scope.list.concat(response.data.list);
            else
                $scope.list = response.data.list;

            $scope.count = response.data.count;
            $scope.searchComplete = true;
            $scope.isInitialSearch = false;
            $scope.isLoading = false;

            if (jumpTo !== 0) {
                if (!firstSearch && scrollSearch && !jumpTo) {
                    jumpTo = $('#professional-search-form').offset().top - $('.site-header__fixed-container').outerHeight() - 40;
                }

                if (jumpTo && jumpTo !== 0 && $scope.count > 0) {
                    var jumpPosition;
                    if (jumpTo > 0) {
                        jumpPosition = jumpTo
                    }
                    else  {
                        var jumpPosition = $('#results').offset().top - $('.site-header__fixed-container').outerHeight();
                        if ($scope.pageNum > 0) {
                            jumpPosition = $('#results tr:last').offset().top + $('#results tr:last').outerHeight() - $('.site-header__fixed-container').outerHeight()
                        }
                    }
                    $timeout( function() {
                        $('html, body').animate({
                            scrollTop: jumpPosition  + 'px'
                        }, 1000);
                    }, 200, true);
                }
            }

            firstSearch = false;
        }).error(function (data, status, headers, config) {

        });
    };

    $scope.isNullOrEmpty = function(value) {
        return helperService.isNullOrEmpty(value);
    };

    $scope.nextPage = function() {
        if (!$scope.isLoading && !$scope.scrollDisabled()) {
            $scope.pageNum++;
            $scope.Search(-1);
        }
    };

    $scope.filtersExists = function() {
        for (var i = 0; i < $scope.filters.length; i++) {
            if($scope.filters[i].values.length > 0){
                return true;
            }
        }
        return false;
    };

    $scope.viewAll = function() {
        $scope.loadAll = true;
        $scope.clearFilters();
    }

    $scope.clearFilters = function() {
        $scope.removeAllFilters();
        angular.forEach(angular.element('.standard-search-field__select-label option'), function(option) {
            angular.element(option).removeAttr('style');
        });
        $scope.pageNum = 0;
        $scope.loadAll = false;
        $scope.Search(0);
    };

    $scope.removeAllFilters = function() {
        filterService.clear();
        $scope.filters = filterService.get();
        $scope.name = '';
        $scope.lastNameLetter = '';
        $scope.administrativeTeam = false;
        $scope.nameTypeAheadList = [];
    };

    $scope.updateFilterForDropDown = function(item) {
        $scope.loadAll = false;
        var itemParam = item.split(';:');
        if(itemParam.length == 3)
        {
            $scope.resetDropDown(itemParam[2]);
            $scope.addFilter(itemParam[0],itemParam[1],itemParam[2]);
        }
        event.currentTarget.blur();
    };

    $scope.resetDropDown= function(sectionname) {
        switch(sectionname)
        {
            case "Industry":
                $scope.industryval = "";
                break;
            case "Service Area":
                $scope.serviceval = "";
                break;
            case "Title":
                $scope.levelval = "";
                break;
            case "Location":
                $scope.locationval = "";
                break;
            case "School":
                $scope.schoolval = "";
                break;
        }
    };

    $scope.changeAdministrativeTeam = function() {
        if (!$scope.administrativeTeam) {
            $scope.addFilter(true, $scope.administrativeTeamLabel, $scope.administrativeTeamSection);
        } else {
            $scope.removeFilter(true, $scope.administrativeTeamLabel, $scope.administrativeTeamSection);
        }
    };

    $scope.addFilter = function (filterValue, filterText, filterSectionName, doNotSearch) {
        if (!helperService.isNullOrEmpty(filterValue) && filterSectionName != $scope.nameSection) {
            var elements = $('.standard-search-form').find('[data-filter-value="' + filterValue + '"]');

            if (elements.length == 1)
            {
                var element = elements[0];
                if (helperService.isNullOrEmpty(filterSectionName))
                {
                    filterSectionName=$(element).data('filter-section');
                }
                if (helperService.isNullOrEmpty(filterText))
                {
                    filterText=$(element).data('filter-name');
                }
            }
        }

        var filter = { name: filterText, value: filterValue, sectionName: filterSectionName };

        switch (filterSectionName) {
          case $scope.administrativeTeamSection:
            $scope.removeAllFilters();
            $scope.administrativeTeam = true;
            $scope.loadAll = true;
            break;
          case $scope.nameSection:
            $scope.lastNameLetter = '';
            filterService.removeBySection($scope.lastNameLetterSection);
            $scope.administrativeTeam = false;
            filterService.removeBySection($scope.administrativeTeamSection);
            break;
          case $scope.lastNameLetterSection:
            $scope.lastNameLetter = '';
            filterService.removeBySection($scope.lastNameLetterSection);
            $scope.administrativeTeam = false;
            filterService.removeBySection($scope.administrativeTeamSection);
            break;
          default:
            $scope.administrativeTeam = false;
            filterService.removeBySection($scope.administrativeTeamSection);
            break;
        }

        if (!filterService.filterExists(filter)) {
            filterService.add(filter);
            $scope.pageNum = 0;

            if (helperService.isNullOrEmpty(doNotSearch) || !doNotSearch) {
                $scope.Search(null);
            }
        }

        if (!(filterSectionName === $scope.lastNameLetterSection || filterSectionName === $scope.nameSection))
            angular.element('option[data-filter-value="' + filterValue + '"]').attr('style', 'display:none;');
    };

    $scope.removeFilter = function(filterValue, filterText, filterSectionName) {
        var filter = { name: filterText, value: filterValue, sectionName:filterSectionName  };
        filterService.remove(filter);

        switch (filterSectionName) {
            case $scope.administrativeTeamSection:
                $scope.administrativeTeam = false;
                break;
            case $scope.lastNameLetterSection:
                $scope.lastNameLetter = '';
                break;
            case $scope.nameSection:
                $scope.name = '';
                break;
            default:
                angular.element('option[data-filter-value="' + filterValue + '"]').removeAttr('style');
                break;
        }

        $scope.loadAll = false;
        $scope.pageNum = 0;
        $scope.Search(0);
    };

    $scope.handleProfessionalClick = function(url) {
        BuildQueryString();
    }

    function scrollToResults() {
        var jumpPosition = $('#results').offset().top - $('.site-header__fixed-container').outerHeight();
        $('html, body').animate({ scrollTop: jumpPosition  + 'px' }, 'slow');
    };

    function BuildQueryString() {
        $scope.queryString = "";
        $scope.queryString = queryStringService.addQueryString('pageSize', $scope.pageSize, $scope.queryString);
        $scope.queryString = queryStringService.addQueryString('pageNum', $scope.pageNum, $scope.queryString);
        $scope.queryString = queryStringService.addQueryString('sortBy', $scope.sortBy, $scope.queryString);
        $scope.queryString = queryStringService.addQueryString('sortOrder', $scope.sortOrder, $scope.queryString);
        $scope.queryString = queryStringService.addQueryString('loadAllByPageSize', $scope.isInitialSearch, $scope.queryString);
        $scope.queryString = queryStringService.addQueryString('loadAll', $scope.loadAll, $scope.queryString);

        if ($window.pageYOffset > 0) {
            $scope.queryString = queryStringService.addQueryString('scroll', $window.pageYOffset, $scope.queryString);
        }

        $scope.queryString = queryStringService.addQueryString('administrativeTeam', $scope.administrativeTeam, $scope.queryString);

        var nameString = filterService.getQueryStringBySection($scope.nameSection, ' ');
        if (!helperService.isNullOrEmpty(nameString)) {
            $scope.queryString = queryStringService.addQueryString('name', nameString, $scope.queryString);
            // if searching by name, include everyone.
            $scope.queryString = queryStringService.removeQueryString('administrativeTeam', $scope.administrativeTeam, $scope.queryString);
        }

        var letterString = filterService.getQueryStringBySection($scope.lastNameLetterSection,'|');
        if(!helperService.isNullOrEmpty(letterString))
            $scope.queryString = queryStringService.addQueryString('lastNameLetter', letterString, $scope.queryString);

        var industryString = filterService.getQueryStringBySection($scope.industriesSection,'|');
        if(!helperService.isNullOrEmpty(industryString))
            $scope.queryString = queryStringService.addQueryString('industries', industryString, $scope.queryString);

        var servicesString = filterService.getQueryStringBySection($scope.servicesSection,'|');
        if(!helperService.isNullOrEmpty(servicesString))
            $scope.queryString = queryStringService.addQueryString('services', servicesString, $scope.queryString);

        var levelsString = filterService.getQueryStringBySection($scope.levelsSection,'|');
        if(!helperService.isNullOrEmpty(levelsString))
            $scope.queryString = queryStringService.addQueryString('levels', levelsString, $scope.queryString);

        var locationsString = filterService.getQueryStringBySection($scope.locationsSection,'|');
        if(!helperService.isNullOrEmpty(locationsString))
            $scope.queryString = queryStringService.addQueryString('locations', locationsString, $scope.queryString);

        var schoolsString = filterService.getQueryStringBySection($scope.schoolsSection,'|');
        if(!helperService.isNullOrEmpty(schoolsString))
            $scope.queryString = queryStringService.addQueryString('schools', schoolsString, $scope.queryString);

        //HISTORY QUERY STRING
        var updatedQueryString = queryStringService.removeQueryString('pageSize', $scope.pageSize, $scope.queryString);
        updatedQueryString = queryStringService.removeQueryString('sortOrder', $scope.sortOrder, updatedQueryString);
        updatedQueryString = queryStringService.removeQueryString('sortBy', $scope.sortBy, updatedQueryString);
        updatedQueryString = queryStringService.removeQueryString('loadAllByPageSize', $scope.isInitialSearch, updatedQueryString);

        if(!$scope.administrativeTeam){
            updatedQueryString = queryStringService.removeQueryString('administrativeTeam', $scope.administrativeTeam, updatedQueryString);
        }

        if ($scope.pageNum <= 0) {
            updatedQueryString = queryStringService.removeQueryString('pageNum', $scope.pageNum, updatedQueryString);
        }

        if (!$scope.loadAll) {
            updatedQueryString = queryStringService.removeQueryString('loadAll', $scope.loadAll, updatedQueryString);
        }

        if (updatedQueryString !== '?') {
            History.replaceState(null, document.title, updatedQueryString.replace('?&', '?'));
        }
    };

    function checkForFilters(filterName) {
        var filterVal = queryStringService.getParameterByName(filterName);
        if(!helperService.isNullOrEmpty(filterVal)) {
            if(filterVal.indexOf('|') > -1)
            {
                var valueArray = filterVal.split('|');
                for(var i=0; i< valueArray.length; i++){
                    $scope.addFilter(valueArray[i],null,null,true);
                }
            }
            else
            {
                if(filterName == 'administrativeTeam'){
                    $scope.addFilter($scope.administrativeTeam, $scope.administrativeTeamLabel, $scope.administrativeTeamSection, true);
                }
                else if(filterName == 'lastNameLetter'){
                    $scope.addFilter(filterVal, filterVal, $scope.lastNameLetterSection, true);
                }
                else if(filterName == 'name'){
                    $scope.name = filterVal;
                    $scope.addFilter(filterVal, filterVal, $scope.nameSection, true);
                }
                else {
                    $scope.addFilter(filterVal, null, null, true);
                }
            }
        }
    };

    function getAvailableLetters() {
        $http.get('/sitecore/api/ssc/webapi/search/1/GetAvailableLetters').success(function (data) {
            for (var i = 0; i < data.AvailableLetters.length; i++) {
                if (data.AvailableLetters[i].toUpperCase() in $scope.letters)
                    $scope.letters[data.AvailableLetters[i].toUpperCase()] = true;
            }
        }).error(function (data, status, headers, config) {

        });
    };

    $scope.init();
}]);
