app.controller('VedderThinking', ['$scope', '$http', '$element', '$sce', 'filterService', 'queryStringService', 'helperService', function($scope, $http, $element, $sce, filterService, queryStringService, helperService) {

    $scope.list = [];

    $scope.searchType = 'TopStories';
    $scope.pageSize = 9;
    $scope.count = 0;
    $scope.pageNum = 0;
    $scope.lists = [];
    $scope.searchComplete = false;
    $scope.isLoading = false;
    $scope.sortOrder = 1;
    $scope.sortBy = 1;
    $scope.isInitialSearch = true;
    var History = window.history;

    $scope.eventSearchType = 'UpcomingEvents';
    $scope.eventSearchTypes = [{
            Id: 'UpcomingEvents',
            Text: 'Upcoming Events'
        },
        {
            Id: 'PastEvents',
            Text: 'Past Events'
        }
    ];

    //All Search Parameters

    //Section Names
    $scope.keywordSection = 'Keyword';
    $scope.industriesSection = 'Industry';
    $scope.practiceSection = 'Practice';
    $scope.professionalsSection = 'Professional';
    $scope.typesSection = 'Type';
    $scope.datesSection = 'Date';

    $scope.scrollDisabled = function() {
        return ((($scope.pageNum + 1) * $scope.pageSize >= $scope.count));
    };

    $scope.init = function() {
        $scope.pageSize = parseInt($element.attr('data-page-size'));
        $scope.sanitizeFields = [];

        if ($element.attr('data-sanitize-field'))
            $scope.sanitizeFields = $element.attr('data-sanitize-field').split(',');
        if ($element.attr('data-sort-by'))
            $scope.sortBy = $element.attr('data-sort-by');
        if ($element.attr('data-sort-order'))
            $scope.sortOrder = $element.attr('data-sort-order');

        //twitter
        if ($element.attr('data-twitter-screenname'))
            screenname = $element.attr('data-twitter-screenname');
        if ($element.attr('data-twitter-count'))
            tweetcount = $element.attr('data-twitter-count');

        $scope.filters = filterService.get();

        //Filters From Query String
        CheckForSearchType();
        CheckForEventSearchType();
        CheckForKeyword();
        CheckForFilters('types');
        CheckForFilters('practices');
        CheckForFilters('industries');
        CheckForFilters('dateselection');

        if (!helperService.isNullOrEmpty(queryStringService.getParameterByName('pageNum')))
            $scope.pageNum = parseInt(queryStringService.getParameterByName('pageNum'));

        if (!helperService.isNullOrEmpty(queryStringService.getParameterByName('sortBy')))
            $scope.sortBy = parseInt(queryStringService.getParameterByName('sortBy'));

        if (!helperService.isNullOrEmpty(queryStringService.getParameterByName('sortOrder')))
            $scope.sortOrder = parseInt(queryStringService.getParameterByName('sortOrder'));

        $scope.Search();

        // twitter
        tweets = FetchTweets(function(respTweets) {
            tweets = respTweets;
            for (var i = 0; i < tweets.length; i++) {
                tweets[i].content = $sce.trustAsHtml(tweets[i].content);
            }
            currentIndex = 0;
            if (tweets.length > 0) {
                $scope.ActiveTweet = tweets[currentIndex];
            }
            navigateUpEle = angular.element('#twitterNavigateUp');
            navigateDownEle = angular.element('#twitterNavigateDown');

            UpdateNavigation();
        });
    };

    $scope.addSearchTerms = function() {
        if (!helperService.isNullOrEmpty($scope.keyword))
            $scope.addFilter($scope.keyword, $scope.keyword, $scope.keywordSection, true);

        $scope.Search();
    };

    $scope.Search = function() {
        $scope.isLoading = true;
        if (!helperService.isNullOrEmpty($scope.keywordval) && $scope.searchType == 'VedderThinking')
            $scope.updateFilterForKeyword($scope.keywordval);
        $scope.resetAllFilterSelections();

        var urlEndPoint = '/sitecore/api/ssc/webapi/search/1/vedderthinking';

        if ($scope.searchType === 'Events') {
          if ($scope.eventSearchType === 'UpcomingEvents') {
            urlEndPoint = '/sitecore/api/ssc/webapi/search/1/upcomingevents';
          } else {
            urlEndPoint = '/sitecore/api/ssc/webapi/search/1/pastevents';
          }
        } else if ($scope.searchType === 'TopStories') {
          urlEndPoint = '/sitecore/api/ssc/webapi/search/1/topstories';
        } else if ($scope.searchType === 'News') {
          urlEndPoint = '/sitecore/api/ssc/webapi/search/1/news';
        } else if ($scope.searchType === 'Articles') {
          urlEndPoint = '/sitecore/api/ssc/webapi/search/1/publications';
        } else if ($scope.searchType === 'Blogs') {
          urlEndPoint = '/sitecore/api/ssc/webapi/search/1/blogs';
        } else if ($scope.searchType === 'CaseStudies') {
          urlEndPoint = '/sitecore/api/ssc/webapi/search/1/casestudies';
        }

        BuildQueryString();
        $http.get(urlEndPoint + $scope.queryString).success(function(response) {
            angular.forEach(response.data.list, function(item) {
                angular.forEach($scope.sanitizeFields, function(fieldName) {
                    var sanitized = $sce.trustAsHtml(item[fieldName]);
                    item[fieldName] = sanitized;
                });
            });

            if ($scope.pageNum > 0)
                $scope.list = $scope.list.concat(response.data.list);
            else
                $scope.list = response.data.list;

            getLists($scope.list);

            $scope.count = response.data.count;
            $scope.searchComplete = true;
            $scope.isInitialSearch = false;
            $scope.isLoading = false;
        }).error(function(data, status, headers, config) {

        });
    };

    var getLists = function(list) {

        var mobileList = shiftedList(list, 1);
        var tabletList = shiftedList(list, 2);
        var desktopList = shiftedList(list, 3 );

        $scope.lists = [{
                className: 'mobile-only',
                list: mobileList
            },
            {
                className: 'tablet-only',
                list: tabletList
            },
            {
                className: 'desktop-only',
                list: desktopList
            }
        ];

    };

    $scope.isNullOrEmpty = function(value) {
        return helperService.isNullOrEmpty(value);
    };

    $scope.nextPage = function() {
        if (!$scope.isLoading && !$scope.scrollDisabled()) {
            $scope.pageNum++;
            $scope.Search();
        }
    };

    $scope.filtersExists = function() {
        for (var i = 0; i < $scope.filters.length; i++) {
            if ($scope.filters[i].values.length > 0)
                return true;
        }
        return false;
    };

    $scope.toggleSearchForm = function() {
        var searchForm = angular.element('#standard-search-form--js');
        searchForm.toggleClass('standard-search-form--is-active');
        var searchTab = angular.element('#vedder-thinking-nav__search-button--js');
        searchTab.toggleClass('vedder-thinking-nav__search-button--is-active');
    };

    $scope.hideSearchForm = function() {
        var searchForm = angular.element('#standard-search-form--js');
        searchForm.removeClass('standard-search-form--is-active');
        var searchTab = angular.element('#vedder-thinking-nav__search-button--js');
        searchTab.removeClass('vedder-thinking-nav__search-button--is-active');
    };

    $scope.changeSearchType = function(newSearchType) {
        if ($scope.searchType != newSearchType) {
            $scope.removeAllFilters();
            $scope.searchType = newSearchType;
            if (newSearchType === 'Events') {
                CheckForEventSearchType($scope.eventSearchType);
            } else {
                $scope.sortOrder = 1;
            }
            $scope.pageNum = 0;
            $scope.isInitialSearch = true;
            $scope.Search();
        }
    };

    $scope.changeEventSearchType = function(newSearchType) {
        if ($scope.eventSearchType != newSearchType) {
            $scope.removeAllFilters();
            $scope.eventSearchType = newSearchType;

            if ($scope.eventSearchType === "UpcomingEvents")
                $scope.sortOrder = 0;
            else
                $scope.sortOrder = 1;

            $scope.pageNum = 0;
            $scope.Search();
        }
    };

    $scope.clearFilters = function() {
        $scope.removeAllFilters();
        angular.forEach(angular.element('#standard-search-form--js option'), function(option) {
            angular.element(option).removeAttr('style');
        });
        $scope.pageNum = 0;
        $scope.searchType = 'TopStories';
        $scope.Search();
    };

    $scope.removeAllFilters = function() {
        filterService.clear();
        $scope.filters = filterService.get();
        $scope.keyword = "";
    };

    $scope.setKeyword = function(item) {
        $scope.keyword = item;
    };

    $scope.updateFilterForKeyword = function(item) {
        $scope.searchType = 'VedderThinking';
        if (item)
            $scope.keyword = item;
        if ($scope.keyword != null && $scope.keyword != '')
            $scope.addFilter($scope.keyword, $scope.keyword, $scope.keywordSection, true);
    };

    $scope.updateFilterForDropDown = function(item) {
        $scope.searchType = 'VedderThinking';
        var itemParam = item.split(';:');
        if (itemParam.length == 3) {
            $scope.resetDropDown(itemParam[2]);
            $scope.addFilter(itemParam[0], itemParam[1], itemParam[2]);
        }
        event.currentTarget.blur();
    };

    $scope.resetDropDown = function(sectionname) {
        switch (sectionname) {
            case 'Industry':
                $scope.industryval = '';
                break;
            case 'Practice':
                $scope.serviceval = '';
                break;
            case 'Professional':
                $scope.professionalval = '';
                break;
            case 'Type':
                $scope.typeval = '';
                break;
            case 'Date':
                $scope.dateval = '';
                break;
        }
    };

    $scope.resetAllFilterSelections = function() {
        $scope.keywordval = '';
        $scope.industryval = '';
        $scope.serviceval = '';
        $scope.typeval = '';
        $scope.dateval = '';
    };

    $scope.addFilter = function(filterValue, filterText, filterSectionName, doNotSearch) {
        // Add check when adding filter
        if (helperService.isNullOrEmpty(doNotSearch))
            doNotSearch = false;

        if (!helperService.isNullOrEmpty(filterValue) && filterSectionName != $scope.keywordSection) {
            var elements = angular.element('.standard-search-form').find('[data-filter-value="' + filterValue + '"]');
            if (elements.length == 1) {
                var element = elements[0];
                if (helperService.isNullOrEmpty(filterSectionName))
                    filterSectionName = angular.element(element).data('filter-section');
                if (helperService.isNullOrEmpty(filterText))
                    filterText = angular.element(element).data('filter-name');
            }
        }
        var filter = { name: filterText, value: filterValue, sectionName: filterSectionName };

        if (filterSectionName === $scope.keywordSection || filterSectionName === $scope.datesSection) {
            filterService.removeBySection(filterSectionName);
            angular.element('option[data-filter-section="' + filterSectionName + '"]').removeAttr('style');
        }

        if (!filterService.filterExists(filter)) {
            filterService.add(filter);
            $scope.pageNum = 0;
            if (!doNotSearch) {
                $scope.Search();
            }
        } else {
            $scope.removeFilter(filterValue, filterText, filterSectionName);
        }

        if (filterSectionName != $scope.keywordSection)
            angular.element('option[data-filter-value="' + filterValue + '"]').attr('style', 'display:none');
    };

    $scope.removeFilter = function(filterValue, filterText, filterSectionName) {
        var filter = { name: filterText, value: filterValue, sectionName: filterSectionName };
        filterService.remove(filter);

        if (filterSectionName === $scope.keywordSection) {
            $scope.keyword = '';
        } else {
            angular.element('option[data-filter-value="' + filterValue + '"]').removeAttr('style');
        }

        $scope.pageNum = 0;

        if (!filterService.anyFilterExists()) {
            $scope.searchType = 'TopStories';
        }

        $scope.Search();
    };

    function CheckForSearchType() {
        var val = queryStringService.getParameterByName('searchType');
        if (val)
            $scope.searchType = val;
    };

    function CheckForEventSearchType(val) {
        if (typeof(val) == "undefined") {
            val = queryStringService.getParameterByName('eventType');
        }
        if (val) {
            if (val === "UpcomingEvents") {
                $scope.sortOrder = 0;
            } else if (val === "PastEvents") {
                $scope.sortOrder = 1;
            }
            $scope.eventSearchType = val;
        }
    };

    function CheckForKeyword() {
        var val = queryStringService.getParameterByName('keyword');
        if (val) {
            $scope.keyword = val;
            $scope.addFilter(val, val, $scope.keywordSection, true);
        }
    };

    function CheckForFilters(filterName) {
        var filterVal = queryStringService.getParameterByName(filterName);
        var sectionName = '';
        switch (filterName) {
            case 'types':
                sectionName = $scope.typesSection;
                break;
            case 'practices':
                sectionName = $scope.practiceSection;
                break;
            case 'industries':
                sectionName = $scope.industriesSection;
                break;
            case 'dateselection':
                sectionName = $scope.datesSection;
                break;
        }
        if (!helperService.isNullOrEmpty(filterVal)) {
            if (filterVal.indexOf('|') > -1) {
                var valueArray = filterVal.split('|');
                for (var i = 0; i < valueArray.length; i++) {
                    $scope.addFilter(valueArray[i], null, sectionName, true);
                }
            } else {
                $scope.addFilter(filterVal, null, sectionName, true);
            }
        }

        angular.element('option[data-filter-value="' + filterVal + '"]').attr('style', 'display:none');

        return filterVal;
    };

    function BuildQueryString() {
        $scope.queryString = "";
        $scope.queryString = queryStringService.addQueryString('pageSize', $scope.pageSize, $scope.queryString);
        $scope.queryString = queryStringService.addQueryString('pageNum', $scope.pageNum, $scope.queryString);
        $scope.queryString = queryStringService.addQueryString('searchType', $scope.searchType, $scope.queryString);
        $scope.queryString = queryStringService.addQueryString('sortBy', $scope.sortBy, $scope.queryString);
        $scope.queryString = queryStringService.addQueryString('sortOrder', $scope.sortOrder, $scope.queryString);
        $scope.queryString = queryStringService.addQueryString('isInsightsPage', $scope.searchType !== 'VedderThinking', $scope.queryString);
        $scope.queryString = queryStringService.addQueryString('loadAllByPageSize', $scope.isInitialSearch, $scope.queryString);
        $scope.queryString = queryStringService.addQueryString('isVedderThinkingPage', true, $scope.queryString);

        if (!helperService.isNullOrEmpty($scope.keyword))
            $scope.queryString = queryStringService.addQueryString('keyword', $scope.keyword, $scope.queryString);

        var letterString = filterService.getQueryStringBySection($scope.lastNameLetterSection, '|');
        if (!helperService.isNullOrEmpty(letterString))
            $scope.queryString = queryStringService.addQueryString('lastNameLetter', letterString, $scope.queryString);

        var industryString = filterService.getQueryStringBySection($scope.industriesSection, '|');
        if (!helperService.isNullOrEmpty(industryString))
            $scope.queryString = queryStringService.addQueryString('industries', industryString, $scope.queryString);

        var practicesString = filterService.getQueryStringBySection($scope.practiceSection, '|');
        if (!helperService.isNullOrEmpty(practicesString))
            $scope.queryString = queryStringService.addQueryString('practices', practicesString, $scope.queryString);

        var professionalsString = filterService.getQueryStringBySection($scope.professionalsSection, '|');
        if (!helperService.isNullOrEmpty(professionalsString))
            $scope.queryString = queryStringService.addQueryString('professionals', professionalsString, $scope.queryString);

        var typesString = filterService.getQueryStringBySection($scope.typesSection, '|');
        if (!helperService.isNullOrEmpty(typesString))
            $scope.queryString = queryStringService.addQueryString('types', typesString, $scope.queryString);

        var locationsString = filterService.getQueryStringBySection($scope.locationsSection, '|');
        if (!helperService.isNullOrEmpty(locationsString))
            $scope.queryString = queryStringService.addQueryString('locations', locationsString, $scope.queryString);

        var datesString = filterService.getQueryStringBySection($scope.datesSection, '|');
        if (!helperService.isNullOrEmpty(datesString))
            $scope.queryString = queryStringService.addQueryString('dateselection', datesString, $scope.queryString);

        if (!helperService.isNullOrEmpty($scope.eventSearchType) && $scope.searchType === 'Events')
            $scope.queryString = queryStringService.addQueryString('eventType', $scope.eventSearchType, $scope.queryString);

        //HISTORY QUERY STRING
        var updatedQueryString = queryStringService.removeQueryString('pageSize', $scope.pageSize, $scope.queryString);
        updatedQueryString = queryStringService.removeQueryString('sortOrder', $scope.sortOrder, updatedQueryString);
        updatedQueryString = queryStringService.removeQueryString('sortBy', $scope.sortBy, updatedQueryString);
        updatedQueryString = queryStringService.removeQueryString('loadAllByPageSize', $scope.isInitialSearch, updatedQueryString);
        updatedQueryString = queryStringService.removeQueryString('isInsightsPage', true, updatedQueryString);
        updatedQueryString = queryStringService.removeQueryString('isInsightsPage', false, updatedQueryString);
        updatedQueryString = queryStringService.removeQueryString('isVedderThinkingPage', true, updatedQueryString);

        if ($scope.searchType === 'TopStories')
            updatedQueryString = queryStringService.removeQueryString('searchType', $scope.searchType, updatedQueryString);

        if ($scope.pageNum <= 0)
            updatedQueryString = queryStringService.removeQueryString('pageNum', $scope.pageNum, updatedQueryString);

        History.replaceState(null, document.title, updatedQueryString.replace('?&', '?'));
    };

    var tweets = [];
    var currentIndex = 0;
    var navigateUpEle;
    var navigateDownEle;
    var screenname;
    var tweetcount = 3;

    $scope.ActiveTweet = null;

    function FetchTweets(callback) {
        var qs = "?name=" + encodeURIComponent(screenname) + "&count=" + encodeURIComponent(tweetcount);
        $http.get('/sitecore/api/ssc/webapi/twitter/1/Get/' + qs).success(function(response) {
            if (callback) {
                callback(response);
            }
            return response;
        }).error(function(data, status, headers, config) {
            $scope.ActiveTweet = null;
        });
    };

    $scope.NavigateUp = function() {
        if (currentIndex > 0) {
            $scope.ActiveTweet = tweets[--currentIndex];
        }
        UpdateNavigation();
    };

    $scope.NavigateDown = function() {
        if (tweets.length > currentIndex + 1) {
            $scope.ActiveTweet = tweets[++currentIndex];
        }
        UpdateNavigation();
    };

    function UpdateNavigation() {
        // show Up when: currentIndex > 0
        if (currentIndex > 0) {
            navigateUpEle.removeClass('disabled').addClass('enabled');
        } else {
            navigateUpEle.removeClass('enabled').addClass('disabled');
        }

        // show Down when: currentIndex < tweets.length
        if (currentIndex + 1 < tweets.length) {
            navigateDownEle.removeClass('disabled').addClass('enabled');
        } else {
            navigateDownEle.removeClass('enabled').addClass('disabled');
        }
    };

    var shiftedList = function(list, numColumns) {

        var lists = [];

        for (var i = 0; i < numColumns; i++) {
            lists[i] = [];
            for (var j = i; j < list.length; j += numColumns) {
                lists[i].push(list[j]);
            }
        }

        return lists;

    };

    $scope.init();
}]);